import React from 'react'
import { FormField, FormLabel, FormInputGroup, SelectionSet, SelectionSetInput, SelectionSetLabel } from '@vp/swan'
import { useController } from 'react-hook-form'
import type { Control } from 'react-hook-form'
import { AccountProfile } from '../models/AccountProfile'
import { MultiOptionsSelectFormFieldName } from '../models/MultiOptionsSelectFormFieldName'
import { useLocalization } from '../hooks/useLocalizations'
import styles from './accountProfile.css'

interface MultiOptionSelectFormFieldProps {
  formLabel: string
  options: { [key: string]: boolean }
  control: Control<AccountProfile>
  selectedOptions: { [key: string]: boolean }
  fieldName: MultiOptionsSelectFormFieldName
  dataTestId?: string
  disabled?: boolean
}

const MultiOptionSelectFormField: React.FC<MultiOptionSelectFormFieldProps> = (props : MultiOptionSelectFormFieldProps) => {
  const { formLabel, options, control, selectedOptions, fieldName, dataTestId, disabled } = props

  const { field } = useController({
    control,
    name: fieldName
  })

  const { t } = useLocalization()

  return (
    <FormField data-testid={dataTestId}>
      <FormLabel>
        {formLabel}
      </FormLabel>
      <FormInputGroup>
        <SelectionSet
          variant='multi-select'
          skin='simple-column'
          name='businessType'
          imageWidth='100px'
          imageVerticalAlignment='center'
          tileContentsPadding='10px'
          tileImagePadding='10px'
          selectedValues={selectedOptions}
          defaultSelectedValues={selectedOptions}
          onSelectedValuesChange={(newSelectedOptions: { [key: string]: boolean }) => {
            Object.assign(selectedOptions, newSelectedOptions)
            field.onChange(selectedOptions)
          }}
        >
          {Object.keys(options).map(option => {
            return (
              <SelectionSetInput
                disabled={disabled}
                className={styles.multiOptionSelectionSetInput}
                key={option}
                value={option}
              >
                <SelectionSetLabel
                  className={styles.multiOptionSelectionSetLabel}
                >{t(option)}
                </SelectionSetLabel>
              </SelectionSetInput>
            )
          })}
        </SelectionSet>
      </FormInputGroup>
    </FormField>
  )
}

export default MultiOptionSelectFormField

import { IndustryData } from '../contexts/industryContext'
import { AccountProfile } from '../models/AccountProfile'
import { DROPDOWN_NOT_SELECTED_VALUE, NO_BUSINESS_OPTION_VALUE } from './constants'

const TOTAL_NUMBER_OF_ACCOUNT_PROFILE_STEPS = 12
const TOTAL_NUMBER_OF_BUSINESS_STEPS = 9

export const calculateTotalNumberOfAccountProfileSteps = (accountProfile?: AccountProfile) : number => {
  if (!accountProfile) return 0

  return TOTAL_NUMBER_OF_ACCOUNT_PROFILE_STEPS
}

export const calculateAccountProfileStep = (accountProfile?: AccountProfile) : number => {
  return calculateBusinessProfileStep(accountProfile) + calculatePersonalProfileStep(accountProfile)
}

const calculateBusinessProfileStep = (accountProfile?: AccountProfile) : number => {
  if (!accountProfile) return 0
  if (accountProfile.businessUse === NO_BUSINESS_OPTION_VALUE) return TOTAL_NUMBER_OF_BUSINESS_STEPS

  let total = 0
  if (hasAnyOptionSelected(accountProfile.businessUse)) total++
  if (accountProfile.businessName) total++
  if (hasIndustrySelected(accountProfile.businessIndustry)) total++
  if (hasAnyOptionSelected(accountProfile.businessSize)) total++
  if (hasAnyOptionSelected(accountProfile.businessLaunchedYear) || accountProfile.businessNotLaunchedYet) total++
  if (hasAnyOptionSelected(accountProfile.businessStage)) total++
  if (hasAnyMultiOptionSelected(accountProfile.businessYouOwnOptionsSelected)) total++
  if (hasAnyMultiOptionSelected(accountProfile.businessYouUseForOptionsSelected)) total++
  if (hasAnyMultiOptionSelected(accountProfile.businessHowYouDesignOptionsSelected)) total++

  return total
}

const calculatePersonalProfileStep = (accountProfile?: AccountProfile) : number => {
  if (!accountProfile) return 0

  let total = 0
  if (hasAnyOptionSelected(accountProfile.personalUse)) total++
  if (hasAnyMultiOptionSelected(accountProfile.personalReasonForShoppingOptionsSelected)) total++
  if (hasAnyMultiOptionSelected(accountProfile.personalWhoYouShopForOptionsSelected)) total++

  return total
}

const hasAnyMultiOptionSelected = (optionsSelected?: { [key: string]: boolean }) : boolean => {
  return !!optionsSelected && Object.values(optionsSelected).some(value => value)
}

const hasAnyOptionSelected = (value?: string) : boolean => {
  return value !== undefined && value !== DROPDOWN_NOT_SELECTED_VALUE
}

const hasIndustrySelected = (industry?: IndustryData) : boolean => {
  return !!industry && (!!industry.industryL1Id || !!industry.industryL2Id || !!industry.industryL3Id)
}

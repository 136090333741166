import { useMutation } from 'react-query'
import { deleteAccountProfileData } from '../services/accountProfileService'
import { AccountProfile } from '../models/AccountProfile'
import { AccountProfileFormData } from '../models/AccountProfileFormData'
import { useLogger } from '@vp/ubik-context'

export const useDeleteAccountProfile = (props: { canonicalId: string, authorizationHeader: string, accountProfileFormData?: AccountProfileFormData }) => {
  const { canonicalId, authorizationHeader, accountProfileFormData } = props

  const logger = useLogger()

  const mutation = useMutation([`DELETE-ACCOUNT-PROFILE-${canonicalId}`],
    async (): Promise<AccountProfile> => {
      if (!canonicalId || !authorizationHeader || !accountProfileFormData) {
        throw new Error('Cannot delete account profile data without a canonicalId or form data')
      }
      return await deleteAccountProfileData({ canonicalId, authorizationHeader, accountProfileFormData, logger })
    })

  const deleteAccountProfile = async (): Promise<AccountProfile> => {
    return mutation.mutateAsync()
  }

  const { isLoading, isError, isSuccess } = mutation
  return {
    deleteAccountProfile,
    isDeletingAccountProfile: isLoading,
    isErrorDeletingAccountProfile: isError,
    isSuccessDeletingAccountProfile: isSuccess
  }
}

import { FlexBox, Spinner, Typography } from '@vp/swan'
import { useLocalization } from '../hooks/useLocalizations'

export const AccountProfileLoading = () => {
  const { t } = useLocalization()

  return (
    <FlexBox
      data-testid='account-profile-loading'
      mt='between-sections'
      mb='between-sections'
      flexDirection='column'
      style={{ height: '100%' }}
      justifyContent='center'
      alignItems='center'
    >
      <Spinner
        size='standard'
        accessibleText={t('Loading')}
      />
      <Typography
        fontSkin='body-standard'
        textColor='subtle'
        padding='6'
      >
        {t('Loading')}
      </Typography>
    </FlexBox>
  )
}

import { AlertBox, AlertBoxDismissButton } from '@vp/swan'
import { useLocalization } from '../hooks/useLocalizations'

export const AccountProfileErrorPage = () => {
  const { t } = useLocalization()

  return (
    <AlertBox skin='error' mt='between-subsections' data-testid='account-profile-error-loading-alert-box'>
      {t('ErrorLoadingAlertText')}
      <AlertBoxDismissButton accessibleText={t('Close')} />
    </AlertBox>
  )
}

import { AccountProfileFormData } from '../models/AccountProfileFormData'
import { INITIAL_YEAR, NO_BUSINESS_OPTION_VALUE } from '../utils/constants'

export const mockAccountProfileFormData = () : AccountProfileFormData => {
  const currentYear = new Date().getFullYear()
  return {
    businessUseOptions: [
      { value: 'Yes', label: 'businessUseOption1' },
      { value: 'No', label: 'businessUseOption2' },
      { value: NO_BUSINESS_OPTION_VALUE, label: 'businessUseOption3' }
    ],
    businessIndustry: {
      industryL1Id: '04c3c1d5ab3844bd82d105edfd1dd948',
      industryL2Id: '9e96223e3cdc4e97a910091c8b0f2e70'
    },
    businessSizeOptions: [
      { value: 'OneEmployee', label: 'businessSizeOption1' },
      { value: 'TwoToFiveEmployees', label: 'businessSizeOption2' },
      { value: 'SixToTenEmployees', label: 'businessSizeOption3' },
      { value: 'ElevenToFiftyEmployees', label: 'businessSizeOption4' },
      { value: 'FiftyOneToOneHundredEmployees', label: 'businessSizeOption5' },
      { value: 'OneHundredOneToTwoHundredFiftyEmployees', label: 'businessSizeOption6' },
      { value: 'TwoHundredFiftyOneToFiveHundredEmployees', label: 'businessSizeOption7' },
      { value: 'OverFiveHundredEmployees', label: 'businessSizeOption8' },
    ],
    businessLaunchedYearOptions:
      Array.from({ length: currentYear - INITIAL_YEAR + 1 }, (_, i) => {
        const year = currentYear - i
        return { value: year.toString(), label: year.toString() }
      }),
    businessStageOptions: [
      { value: 'NewBusiness', label: 'businessStageOption1' },
      { value: 'EstablishedBusiness', label: 'businessStageOption2' },
      { value: 'Other', label: 'businessStageOption3' },
    ],
    businessYouOwnOptions: {
      OnlineECommerce: false,
      InStoreBrickAndMortar: false,
      MobileOnTheGo: false,
      PopUpShopsAndTemporaryLocations: false,
      Other: false
    },
    businessYouUseForOptions: {
      StrengthenMyBranding: false,
      LaunchMyNewBusiness: false,
      GrowMyLocalPresence: false,
      PrepareForAnEvent: false,
      CreateEngagingGiftsAndGiveaways: false,
      OutfitMyTeam: false,
      Other: false
    },
    businessHowYouDesignOptions: {
      UseVistaPrintTemplate: false,
      UseThirdPartyTool: false,
      HaveDesigner: false,
      NeedHelpDesign: false
    },
    personalUseOptions: [
      { value: 'Yes', label: 'personalUseOption1' },
      { value: 'No', label: 'personalUseOption2' }
    ],
    personalReasonOptions: {
      PartiesAndEvents: false,
      Gifting: false,
      HomeAndPersonalUse: false,
      Holidays: false,
      Other: false
    },
    personalWhoOptions: {
      Myself: false,
      SpouseOrSignificantOther: false,
      Children: false,
      ParentsOrExtendedFamily: false,
      Friends: false,
      Other: false,
    },
  }
}

import { ProgressBarContainer, FlexBox, ProgressOutput, VisuallyHidden, Progress, Icon, Typography } from '@vp/swan'
import { useLocalization } from '../hooks/useLocalizations'

export interface AccountProfileProgressBarProps {
  numberOfSteps: number
  currentStep: number
}

export const AccountProfileProgressBar = (props: AccountProfileProgressBarProps) => {
  const { numberOfSteps, currentStep } = props
  const progressValue = (currentStep / numberOfSteps) * 100
  const progressText = `${currentStep}/${numberOfSteps}`
  const isAccountProfileCompleted = currentStep === numberOfSteps
  const { t } = useLocalization()
  const visuallyHiddenText = t('progressBarText', { currentStep: currentStep.toString(), numberOfSteps: numberOfSteps.toString() })
  return (
    <ProgressBarContainer>
      <FlexBox
        data-testid='progress-bar-flex-box'
        mt='between-subsections'
        alignItems='center'
      >
        <ProgressOutput mr='4' data-testid='progress-bar-output'>
          <VisuallyHidden>{visuallyHiddenText}</VisuallyHidden>
          {isAccountProfileCompleted && (<Icon data-testId='progress-bar-completed-icon' iconType='success' size='standard' skin='standard' />)}
          {!isAccountProfileCompleted && (<Typography fontSkin='body-standard-bold'>{progressText}</Typography>)}
        </ProgressOutput>
        <Progress mt='1' data-testid='progress-bar' value={progressValue} />
      </FlexBox>
    </ProgressBarContainer>
  )
}

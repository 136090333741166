import { useState, useEffect, useCallback } from 'react'
import { REQUESTOR, URL_SERVICE_API_URL } from '../utils/constants'

interface LocalizedLinkParams {
  tenant: string;
  locale: string;
  page: string;
}

const useLocalizedLink = ({ tenant, locale, page }: LocalizedLinkParams) => {
  const [link, setLink] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  const fetchLink = useCallback(async () => {
    try {
      const response = await fetch(`${URL_SERVICE_API_URL}/${tenant}/${locale}/${page}?requestor=${REQUESTOR}`)
      if (!response.ok) {
        throw new Error(`Error while fetching link for tenant: ${tenant}, locale: ${locale}, page: ${page}`)
      }
      const data = await response.json()
      setLink(data?.url)
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      } else {
        setError(String(err))
      }
    }
  }, [tenant, locale, page])

  useEffect(() => {
    fetchLink()
  }, [tenant, locale, page, fetchLink])

  return { link, error }
}

export default useLocalizedLink

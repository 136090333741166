import { fetchAccountProfileStoredData, fetchAccountProfileFormData, storeAccountProfileData, deleteAccountProfileStoredData } from '../clients/AccountProfileClient'
import { AccountProfileStoredData } from '../models/AccountProfileStoredData'
import { AccountProfile } from '../models/AccountProfile'
import { AccountProfileFormData } from '../models/AccountProfileFormData'
import { buildAccountProfileFromStoredData, buildEmptyAccountProfile } from '../factories/accountProfileFactory'
import { buildAccountProfileToBeStored } from '../factories/accountProfileStoredDataFactory'
import { Logger } from '@vp/ubik-logging'

export const fetchAccountProfileFormDataToDisplay = async (locale: string): Promise<AccountProfileFormData> => {
  return await fetchAccountProfileFormData(locale)
}

export const fetchAccountProfile = async (
  props: { canonicalId: string, authorizationHeader: string, accountProfileFormData: AccountProfileFormData, logger: Logger }
): Promise<AccountProfile> => {
  const { canonicalId, authorizationHeader, accountProfileFormData, logger } = props
  const accountProfileStoredData: AccountProfileStoredData = await fetchAccountProfileStoredData({ canonicalId, authorizationHeader, logger })
  const accountProfile = buildAccountProfileFromStoredData({ accountProfileFormData, accountProfileStoredData })
  return accountProfile
}

export const storeAccountProfile = async (
  props: { accountProfile: AccountProfile, canonicalId: string, authorizationHeader: string, logger: Logger }
) => {
  const { accountProfile, canonicalId, authorizationHeader, logger } = props

  const accountProfileDataToStore = buildAccountProfileToBeStored(accountProfile)
  return await storeAccountProfileData({ accountProfileDataToStore, canonicalId, authorizationHeader, logger })
}

export const deleteAccountProfileData = async (
  props: { canonicalId: string, authorizationHeader: string, accountProfileFormData: AccountProfileFormData, logger: Logger }
) => {
  const { canonicalId, authorizationHeader, accountProfileFormData, logger } = props
  await deleteAccountProfileStoredData({ canonicalId, authorizationHeader, logger })
  return buildEmptyAccountProfile(accountProfileFormData)
}

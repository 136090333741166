import { IndustryData } from '../contexts/industryContext'
import { AccountProfile } from '../models/AccountProfile'
import { AccountProfileFormData } from '../models/AccountProfileFormData'
import { AccountProfileStoredData } from '../models/AccountProfileStoredData'
import { DROPDOWN_NOT_SELECTED_VALUE } from '../utils/constants'
import { buildEmptyAccountProfileStoredData } from './accountProfileStoredDataFactory'

export const buildEmptyAccountProfile =
  (accountProfileFormData: AccountProfileFormData): AccountProfile => {
    const emptyAccountProfileData: AccountProfileStoredData = buildEmptyAccountProfileStoredData()
    const emptyAccountProfile: AccountProfile = buildAccountProfileFromStoredData(
      { accountProfileFormData, accountProfileStoredData: emptyAccountProfileData })
    return emptyAccountProfile
  }

export const buildAccountProfileFromStoredData = (props:
{ accountProfileFormData: AccountProfileFormData, accountProfileStoredData: AccountProfileStoredData }): AccountProfile => {
  const { accountProfileFormData, accountProfileStoredData } = props
  const {
    businessYouOwnOptions,
    businessYouUseForOptions,
    businessHowYouDesignOptions,
    personalReasonOptions,
    personalWhoOptions
  } = accountProfileFormData || {}
  const {
    businessYouOwn,
    businessYouUseFor,
    businessHowYouDesign,
    personalReason,
    personalWho
  } = accountProfileStoredData
  return {
    isNewAccountProfile: accountProfileStoredData.isNewAccountProfile,
    businessUse: accountProfileStoredData.businessUse ?? 'undefined',
    businessName: accountProfileStoredData.businessName ?? '',
    businessIndustry: accountProfileStoredData.businessIndustry ? { ...accountProfileStoredData.businessIndustry } : {} as IndustryData,
    businessSize: accountProfileStoredData.businessSize ?? 'undefined',
    businessLaunchedYear: formatNumberToDropdownValue(accountProfileStoredData.businessLaunchedYear),
    businessNotLaunchedYet: formatBooleanToCheckboxValue(accountProfileStoredData.businessNotLaunchedYet),
    businessStage: accountProfileStoredData.businessStage ?? 'undefined',
    personalUse: accountProfileStoredData.personalUse ?? 'undefined',
    businessYouOwnOptionsSelected: setSelectedOptions(businessYouOwnOptions, businessYouOwn),
    businessYouUseForOptionsSelected: setSelectedOptions(businessYouUseForOptions, businessYouUseFor),
    businessHowYouDesignOptionsSelected: setSelectedOptions(businessHowYouDesignOptions, businessHowYouDesign),
    personalReasonForShoppingOptionsSelected: setSelectedOptions(personalReasonOptions, personalReason),
    personalWhoYouShopForOptionsSelected: setSelectedOptions(personalWhoOptions, personalWho)
  }
}

const setSelectedOptions = (options: { [key: string]: boolean }, selectedOptions?: string[]): { [key: string]: boolean } => {
  if (!selectedOptions) {
    return {}
  }
  return Object.keys(options).reduce((acc: { [key: string]: boolean }, option) => {
    acc[option] = selectedOptions.includes(option)
    return acc
  }
  , {})
}

const formatNumberToDropdownValue = (value?: number): string => {
  return value ? value.toString() : DROPDOWN_NOT_SELECTED_VALUE
}

const formatBooleanToCheckboxValue = (value?: boolean): boolean => {
  return value === true
}

import { useQuery } from 'react-query'
import { useState } from 'react'
import { AccountProfile } from '../models/AccountProfile'
import { fetchAccountProfile } from '../services/accountProfileService'
import { useLogger } from '@vp/ubik-context'

export const useAccountProfile = (props: { canonicalId: string, authorizationHeader: string, accountProfileFormData: any }) => {
  const [accountProfile, setAccountProfile] = useState<AccountProfile>()

  const { canonicalId, authorizationHeader, accountProfileFormData } = props

  const logger = useLogger()

  const { isLoading, isError } = useQuery(
    [`GET-ACCOUNT-PROFILE-${canonicalId}`],
    async () => {
      if (!canonicalId || !accountProfileFormData) {
        return
      }
      return await fetchAccountProfile({ canonicalId, authorizationHeader, accountProfileFormData, logger })
    },
    {
      onSuccess: (data: AccountProfile) => {
        if (data) {
          setAccountProfile(data)
        }
      },
      onError: (error) => {
        logger.error('Error fetching account profile:', error)
      },
      enabled: !!canonicalId && !!authorizationHeader && !!accountProfileFormData
    }
  )

  return { loadedAccountProfile: accountProfile, isLoadingAccountProfile: isLoading, isErrorLoadingAccountProfile: isError }
}

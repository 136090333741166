export const DEFAULT_LOCALE = 'en-ie'
export const SIGN_UP_OPTION = 'signUp'

export const CUSTOMER_PROFILE_PERSONALIZATION_API_URL = 'https://dev.customer-profile.personalization.vpsvc.com/api/v2/account-profile/me'
export const REQUESTOR = 'account-profile-page'

export const DROPDOWN_NOT_SELECTED_VALUE = 'undefined'

export const URL_SERVICE_API_URL = 'https://url.prod.merch.vpsvc.com/v3/url'
export const PRIVACY_AND_COOKIE_POLICY_PAGE_ID = 'static:PrivacyAndCookiePolicy'

export const INITIAL_YEAR = 2000

export const NO_BUSINESS_OPTION_VALUE = 'NoBusiness'

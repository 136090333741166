import { BusinessSize } from './BusinessSize'
import { BusinessStage } from './BusinessStage'
import { BusinessUse } from './BusinessUse'
import { PersonalUse } from './PersonalUse'
import { IndustryData } from '../contexts/industryContext'

export interface AccountProfile {
  isNewAccountProfile?: boolean,
  businessUse?: BusinessUse,
  businessName?: string,
  businessIndustry?: IndustryData,
  businessSize?: BusinessSize,
  businessLaunchedYear?: string,
  businessNotLaunchedYet?: boolean,
  businessStage?: BusinessStage,
  businessYouOwnOptionsSelected?: { [key: string]: boolean },
  businessYouUseForOptionsSelected?: { [key: string]: boolean },
  businessHowYouDesignOptionsSelected?: { [key: string]: boolean },
  personalUse?: PersonalUse,
  personalReasonForShoppingOptionsSelected?: { [key: string]: boolean },
  personalWhoYouShopForOptionsSelected?: { [key: string]: boolean },
}

export const cloneAccountProfile = (accountProfile?: AccountProfile) : AccountProfile => {
  return {
    businessName: accountProfile?.businessName,
    businessIndustry: accountProfile?.businessIndustry,
    businessUse: accountProfile?.businessUse,
    businessSize: accountProfile?.businessSize,
    businessLaunchedYear: accountProfile?.businessLaunchedYear,
    businessNotLaunchedYet: accountProfile?.businessNotLaunchedYet ?? false,
    businessStage: accountProfile?.businessStage,
    personalUse: accountProfile?.personalUse,
    businessYouOwnOptionsSelected: { ...accountProfile?.businessYouOwnOptionsSelected },
    businessYouUseForOptionsSelected: { ...accountProfile?.businessYouUseForOptionsSelected },
    businessHowYouDesignOptionsSelected: { ...accountProfile?.businessHowYouDesignOptionsSelected },
    personalReasonForShoppingOptionsSelected: { ...accountProfile?.personalReasonForShoppingOptionsSelected },
    personalWhoYouShopForOptionsSelected: { ...accountProfile?.personalWhoYouShopForOptionsSelected }
  }
}

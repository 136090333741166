import { DEFAULT_LOCALE } from '../utils/constants'

const BASE_URL = 'https://taxonomy.personalization.vpsvc.com'

interface IndustryItem {
  id: string;
  label: string;
  level1_id?: string;
  level2_id?: string;
}

interface IndustriesResponse {
  levels: {
    level1: { items: IndustryItem[] };
    level2: { items: IndustryItem[] };
    level3: { items: IndustryItem[] };
  };
}

export const fetchIndustries = async (locale: string = DEFAULT_LOCALE): Promise<IndustriesResponse> => {
  const response = await fetch(`${BASE_URL}/v1/industry/${locale}`)
  if (!response.ok) {
    throw new Error(`Failed to fetch industries for locale: ${locale}`)
  }
  return await response.json() as IndustriesResponse
}

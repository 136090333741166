import { useIdentity, useUserContext, useSwanStyleKeys, useStyles, useLogger } from '@vp/ubik-context'
import { AccountProfileForm, AccountProfileFormProps } from './AccountProfileForm'
import { Column, GridContainer, Row, SWAN_STYLE_KEY_MAP } from '@vp/swan'
import QueryClientWrapper from './QueryClientWrapper'
import { AccountProfileEmptyState } from './AccountProfileEmptyState'
import { AccountProfileLoading } from './AccountProfileLoading'
import styles, { stylesheet } from './accountProfile.css'
import { SIGN_UP_OPTION } from '../utils/constants'
import { getIndustries } from '../services/industryTaxonomyService'
import { useEffect, useState } from 'react'
import { IndustryContextProps, IndustryProvider } from '../contexts/industryContext'

export interface AccountProfileFragmentProps {
  localizations: Record<string, any>
  locale: string
}

export const Fragment = (props: AccountProfileFragmentProps) => {
  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.alertBox,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.modalDialog,
    SWAN_STYLE_KEY_MAP.selectionSet,
    SWAN_STYLE_KEY_MAP.progressBar,
    SWAN_STYLE_KEY_MAP.spinner,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.emptyState,
    SWAN_STYLE_KEY_MAP.listbox,
  ])
  useStyles(stylesheet)

  const logger = useLogger()
  const userContext = useUserContext()
  const { locale } = userContext
  const { identity, auth } = useIdentity()

  const canonicalId = auth?.getCanonicalId()
  const authorizationHeader = identity?.authorizationHeader

  const [industries, setIndustries] = useState<IndustryContextProps>({
    allIndustries: [],
    level1Industries: [],
  })

  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const industriesData = await getIndustries(locale)
        setIndustries(industriesData)
      } catch (error) {
        logger.error('Error fetching Industries:', error)
      }
    }

    fetchIndustries()
  }, [])

  const accountProfileFormProps: AccountProfileFormProps = {
    canonicalId: canonicalId ?? '',
    authorizationHeader: authorizationHeader ?? '',
    locale
  }

  const loadingIdentity = !identity
  const isSignedIn = identity?.authorizationHeader && identity?.isSignedIn

  return (
    <QueryClientWrapper>
      <GridContainer>
        <Row>
          <Column className={styles.accountProfileColumnContainer}>
            {loadingIdentity && <AccountProfileLoading />}
            {!loadingIdentity && !isSignedIn && (
              <AccountProfileEmptyState
                onPrimaryButtonClick={() => (auth as any)?.signIn([], [], { navHint: '' })}
                onSecondaryButtonClick={() => (auth as any)?.signIn([], [], { navHint: SIGN_UP_OPTION })}
              />
            )}
            {isSignedIn && (
              <IndustryProvider allIndustries={industries.allIndustries} level1Industries={industries.level1Industries}>
                <AccountProfileForm
                  {...accountProfileFormProps}
                />
              </IndustryProvider>
            )}
          </Column>
        </Row>
      </GridContainer>
    </QueryClientWrapper>
  )
}

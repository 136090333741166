import { clientMount } from '@vp/ubik-fragment-react'
import type { MountWithRootOptions } from '@vp/ubik-fragment-types'
import { Fragment, AccountProfileFragmentProps } from '../components/fragment'
import { IdentityProvider, } from '@vp/ubik-context'
import { LocalizationsProvider } from '../hooks/useLocalizations'
import { createAuthConfig } from '../common/getAuthConfig'

export const mount: MountWithRootOptions<AccountProfileFragmentProps> = async (rootElement, renderProps, rootOptions) => {
  const { locale, localizations } = renderProps

  const component = (
    <IdentityProvider auth={createAuthConfig(locale)}>
      <LocalizationsProvider localizations={localizations}>
        <Fragment {...renderProps} />
      </LocalizationsProvider>
    </IdentityProvider>
  )

  return clientMount(component, rootElement, rootOptions)
}

import { EmptyState, EmptyStateIllustration, EmptyStateTitle, EmptyStateDescription, EmptyStateActions, Button } from '@vp/swan'
import { useLocalization } from '../hooks/useLocalizations'

export interface AccountProfileEmptyStateProps {
  onPrimaryButtonClick: () => void
  onSecondaryButtonClick: () => void
}

export const AccountProfileEmptyState = (props: AccountProfileEmptyStateProps) => {
  const { onPrimaryButtonClick, onSecondaryButtonClick } = props
  const { t } = useLocalization()
  return (
    <EmptyState
      data-testid='account-profile-empty-state'
      mt='between-sections'
      mb='between-sections'
    >
      <EmptyStateIllustration illustrationType='generic' />
      <EmptyStateTitle data-testid='empty-state-title'>{t('accountProfileEmptyStateTitle')}</EmptyStateTitle>
      <EmptyStateDescription data-testid='empty-state-description'>{t('accountProfileEmptyStateDescription')}</EmptyStateDescription>
      <EmptyStateActions>
        <Button data-testid='empty-state-primary-button' skin='primary' onClick={onPrimaryButtonClick}>
          {t('accountProfileEmptyStatePrimaryButton')}
        </Button>
        <Button data-testid='empty-state-secondary-button' skin='secondary' onClick={onSecondaryButtonClick}>
          {t('accountProfileEmptyStateSecondaryButton')}
        </Button>
      </EmptyStateActions>
    </EmptyState>
  )
}

import React, { FC } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

const QueryClientWrapper: FC<{ retry?: number; children: any }> = ({ retry, children }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: retry ?? 1,
        onError: (error: any) => {
          processError(error)
        }
      },
      mutations: {
        retry: retry ?? 1,
        onError: (error: any) => {
          processError(error)
        }
      }
    }
  })

  const processError = (error: any) => {
    const context = {
      url: error?.config?.url,
      method: error?.config?.method,
      data: error?.config?.data,
      errorMessage: error?.message,
      statusCode: error?.statusCode
    }
    console.error('Error processing request', context)
  }
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

export default QueryClientWrapper

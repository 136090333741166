import { useQuery } from 'react-query'
import { useState } from 'react'
import { AccountProfileFormData } from '../models/AccountProfileFormData'
import { fetchAccountProfileFormDataToDisplay } from '../services/accountProfileService'
import { useLogger } from '@vp/ubik-context'

export const useAccountProfileFormData = (locale: string) => {
  const [accountProfileFormData, setAccountProfileFormData] = useState<AccountProfileFormData>()

  const logger = useLogger()

  const { isError, isLoading } = useQuery(
    [`GET-FORM-DATA-TO-DISPLAY-${locale}`],
    async () => {
      return await fetchAccountProfileFormDataToDisplay(locale)
    },
    {
      onSuccess: (data: AccountProfileFormData) => {
        setAccountProfileFormData(data)
      },
      onError: (error) => {
        logger.error('Error fetching account profile form data:', error)
      },
      enabled: !!locale
    }
  )

  return { accountProfileFormData, isLoadingFormData: isLoading, isErrorLoadingFormData: isError }
}

import { fetchIndustries } from '../clients/industryTaxonomyClient'
import { DEFAULT_LOCALE } from '../utils/constants'

const fetchIndustriesByLocale = async (locale: string) => {
  try {
    return await fetchIndustries(locale)
  } catch (error) {
    console.warn(`Industries for ${locale} are not set, default ${DEFAULT_LOCALE} locale will be used`)
    return await fetchIndustries(DEFAULT_LOCALE)
  }
}

export const getIndustries = async (locale: string) => {
  const industries = await fetchIndustriesByLocale(locale.toLowerCase())
  const level1Industries = industries.levels.level1.items.map(item => ({
    industryData: {
      industryL1Id: item.id ?? '',
      industryL2Id: undefined,
      industryL3Id: undefined,
    },
    industryId: item.id,
    industryLabel: item.label,
  }))
  const level2Industries = industries.levels.level2.items.map(item => ({
    industryData: {
      industryL1Id: item.level1_id ?? '',
      industryL2Id: item.id,
      industryL3Id: undefined,
    },
    industryId: item.id,
    industryLabel: item.label,
  }))
  const level3Industries = industries.levels.level3.items.map(item => ({
    industryData: {
      industryL1Id: item.level1_id ?? '',
      industryL2Id: item.level2_id ?? '',
      industryL3Id: item.id,
    },
    industryId: item.id,
    industryLabel: item.label,
  }))
  return {
    allIndustries: [...level1Industries, ...level2Industries, ...level3Industries],
    level1Industries,
  }
}

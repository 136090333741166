import { useMutation } from 'react-query'
import { storeAccountProfile } from '../services/accountProfileService'
import { AccountProfile } from '../models/AccountProfile'
import { useLogger } from '@vp/ubik-context'

export const useSaveAccountProfile = (props: { canonicalId: string, authorizationHeader: string }) => {
  const { canonicalId, authorizationHeader } = props

  const logger = useLogger()

  const mutation = useMutation([`SAVE-ACCOUNT-PROFILE-${canonicalId}`],
    async (accountProfile: AccountProfile): Promise<void> => {
      if (!canonicalId || !authorizationHeader || !accountProfile) {
        throw new Error('Cannot save account profile data without a canonicalId or form data')
      }
      return await storeAccountProfile({ canonicalId, authorizationHeader, accountProfile, logger })
    })

  const saveAccountProfile = async (accountProfile: AccountProfile) => {
    await mutation.mutateAsync(accountProfile)
  }

  const { isLoading, isError, isSuccess } = mutation
  return {
    saveAccountProfile,
    isSavingAccountProfile: isLoading,
    isErrorSavingAccountProfile: isError,
    isSuccessSavingAccountProfile: isSuccess
  }
}

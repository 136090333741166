import { PropsWithChildren, createContext, useContext } from 'react'

export interface IndustryData {
  industryL1Id?: string
  industryL2Id?: string
  industryL3Id?: string
}

export interface Industry {
  industryData: IndustryData
  industryId: string
  industryLabel: string
}

export interface IndustryContextProps {
  allIndustries: Industry[]
  level1Industries: Industry[]
}

export interface IndustryProviderProps extends PropsWithChildren {
  allIndustries: Industry[]
  level1Industries: Industry[]
}

const IndustryContext = createContext<IndustryContextProps>({
  allIndustries: [],
  level1Industries: [],
})

const IndustryProvider = ({ allIndustries, level1Industries, children }: IndustryProviderProps) => {
  return (
    <IndustryContext.Provider
      value={{
        allIndustries,
        level1Industries,
      }}
    >
      {children}
    </IndustryContext.Provider>
  )
}

const useIndustryContext = () => useContext(IndustryContext)

export { IndustryContext, IndustryProvider, useIndustryContext }

import { getTracking } from '@vp/tracking'
import { AccountProfile } from '../models/AccountProfile'
import { calculateAccountProfileStep, calculateTotalNumberOfAccountProfileSteps } from './accountProfileStepsCalculator'
import { IndustryData } from '../contexts/industryContext'

const ACCOUNT_PROFILE_CATEGORY = 'Account Profile'
const SAVE_ACCOUNT_PROFILE_CLICKED_EVENT_NAME = 'Button Clicked'
const SAVE_ACCOUNT_PROFILE_CLICKED_LABEL = 'Save profile'
const IDENTITY_ADDED_EVENT_NAME = 'Identity Added'
const IDENTITY_UPDATED_EVENT_NAME = 'Identity Updated'
const YES = 'YES'
const NO = 'NO'
const ANSWERS_SEPARATOR = '_'
const QUESTION_SEPARATOR = ':'

const FLY_OUT_CATEGORY = 'Fly-Out'
const FLY_OUT_VIEWED_EVENT_NAME = 'Fly-Out Viewed'
const FLY_OUT_CLICKED_EVENT_NAME = 'Fly-Out Clicked'

const FLY_OUT_CANCEL_CLICKED_LABEL = 'Account Profile Cancel Fly-Out'
const FLY_OUT_CANCEL_CONFIRM_CLICKED_DETAIL = 'Account Profile Cancel Confirm'
const FLY_OUT_CANCEL_DISCARD_CLICKED_DETAIL = 'Account Profile Cancel Discard'

const FLY_OUT_DELETE_CLICKED_LABEL = 'Account Profile Delete Fly-Out'
const FLY_OUT_DELETE_CONFIRM_CLICKED_DETAIL = 'Account Profile Delete Confirm'
const FLY_OUT_DELETE_DISCARD_CLICKED_DETAIL = 'Account Profile Delete Discard'

export const trackCancelAccountProfileClickedEvent = () => {
  const tracking = getTracking()
  if (!tracking) { return }
  tracking.track(
    FLY_OUT_VIEWED_EVENT_NAME,
    {
      category: FLY_OUT_CATEGORY,
      label: FLY_OUT_CANCEL_CLICKED_LABEL,
    },
    {}
  )
}

export const trackCancelAccountProfileConfirmClickedEvent = () => {
  const tracking = getTracking()
  if (!tracking) { return }
  tracking.track(
    FLY_OUT_CLICKED_EVENT_NAME,
    {
      category: FLY_OUT_CATEGORY,
      label: FLY_OUT_CANCEL_CLICKED_LABEL,
      eventDetail: FLY_OUT_CANCEL_CONFIRM_CLICKED_DETAIL,
    },
    {}
  )
}

export const trackCancelAccountProfileDiscardClickedEvent = () => {
  const tracking = getTracking()
  if (!tracking) { return }
  tracking.track(
    FLY_OUT_CLICKED_EVENT_NAME,
    {
      category: FLY_OUT_CATEGORY,
      label: FLY_OUT_CANCEL_CLICKED_LABEL,
      eventDetail: FLY_OUT_CANCEL_DISCARD_CLICKED_DETAIL,
    },
    {}
  )
}

export const trackDeleteAccountProfileClickedEvent = () => {
  const tracking = getTracking()
  if (!tracking) { return }
  tracking.track(
    FLY_OUT_VIEWED_EVENT_NAME,
    {
      category: FLY_OUT_CATEGORY,
      label: FLY_OUT_DELETE_CLICKED_LABEL,
    },
    {}
  )
}

export const trackDeleteAccountProfileConfirmClickedEvent = () => {
  const tracking = getTracking()
  if (!tracking) { return }
  tracking.track(
    FLY_OUT_CLICKED_EVENT_NAME,
    {
      category: FLY_OUT_CATEGORY,
      label: FLY_OUT_DELETE_CLICKED_LABEL,
      eventDetail: FLY_OUT_DELETE_CONFIRM_CLICKED_DETAIL,
    },
    {}
  )
}

export const trackDeleteAccountProfileDiscardClickedEvent = () => {
  const tracking = getTracking()
  if (!tracking) { return }
  tracking.track(
    FLY_OUT_CLICKED_EVENT_NAME,
    {
      category: FLY_OUT_CATEGORY,
      label: FLY_OUT_DELETE_CLICKED_LABEL,
      eventDetail: FLY_OUT_DELETE_DISCARD_CLICKED_DETAIL,
    },
    {}
  )
}

export const trackSaveAccountProfileClickedEvent = () => {
  const tracking = getTracking()
  if (!tracking) { return }

  tracking.track(
    SAVE_ACCOUNT_PROFILE_CLICKED_EVENT_NAME,
    {
      category: ACCOUNT_PROFILE_CATEGORY,
      label: SAVE_ACCOUNT_PROFILE_CLICKED_LABEL,
    },
    {}
  )
}

export const trackAccountProfileSavedEvent = (params: {
  accountProfile: AccountProfile,
  previousAccountProfile?: AccountProfile
}) => {
  const tracking = getTracking()
  if (!tracking) { return }

  const { accountProfile, previousAccountProfile } = params

  const eventName = accountProfile.isNewAccountProfile ? IDENTITY_ADDED_EVENT_NAME : IDENTITY_UPDATED_EVENT_NAME

  const questionsAnswered = calculateAccountProfileStep(accountProfile)
  const totalNumberOfQuestions = calculateTotalNumberOfAccountProfileSteps(previousAccountProfile)
  const questionsWithAnswers = buildQuestionsWithAnswers(accountProfile, previousAccountProfile)
  const eventDetail = buildEventDetail(questionsWithAnswers)

  tracking.track(
    eventName,
    {
      category: ACCOUNT_PROFILE_CATEGORY,
      label: `${questionsAnswered}${ANSWERS_SEPARATOR}${totalNumberOfQuestions}`,
      eventDetail,
    },
    {}
  )
}

const buildQuestionsWithAnswers = (accountProfile: AccountProfile, previousAccountProfile?: AccountProfile): { [key:string]: boolean } => {
  const questionsWithAnswers: { [key:string]: boolean } = {}
  questionsWithAnswers['businessUse'] = accountProfile.businessUse !== previousAccountProfile?.businessUse
  questionsWithAnswers['businessName'] = accountProfile.businessName !== previousAccountProfile?.businessName
  questionsWithAnswers['businessIndustry'] = hasIndustryChanged(accountProfile.businessIndustry, previousAccountProfile?.businessIndustry)
  questionsWithAnswers['businessSize'] = accountProfile.businessSize !== previousAccountProfile?.businessSize
  questionsWithAnswers['businessLaunchedYear'] = accountProfile.businessLaunchedYear !== previousAccountProfile?.businessLaunchedYear
  questionsWithAnswers['businessNotLaunchedYet'] = accountProfile.businessNotLaunchedYet !== previousAccountProfile?.businessNotLaunchedYet
  questionsWithAnswers['businessStage'] = accountProfile.businessStage !== previousAccountProfile?.businessStage
  questionsWithAnswers['businessYouOwnOptionsSelected'] = hasSelectionChanged(accountProfile.businessYouOwnOptionsSelected, previousAccountProfile?.businessYouOwnOptionsSelected)
  questionsWithAnswers['businessYouUseForOptionsSelected'] = hasSelectionChanged(accountProfile.businessYouUseForOptionsSelected, previousAccountProfile?.businessYouUseForOptionsSelected)
  questionsWithAnswers['businessHowYouDesignOptionsSelected'] = hasSelectionChanged(accountProfile.businessHowYouDesignOptionsSelected, previousAccountProfile?.businessHowYouDesignOptionsSelected)
  questionsWithAnswers['personalUse'] = accountProfile.personalUse !== previousAccountProfile?.personalUse
  questionsWithAnswers['personalReasonForShoppingOptionsSelected'] = hasSelectionChanged(accountProfile.personalReasonForShoppingOptionsSelected, previousAccountProfile?.personalReasonForShoppingOptionsSelected)
  questionsWithAnswers['personalWhoYouShopForOptionsSelected'] = hasSelectionChanged(accountProfile.personalWhoYouShopForOptionsSelected, previousAccountProfile?.personalWhoYouShopForOptionsSelected)
  return questionsWithAnswers
}

const hasIndustryChanged = (businessIndustry?: IndustryData, previousBusinessIndustry?: IndustryData): boolean => {
  return businessIndustry?.industryL1Id !== previousBusinessIndustry?.industryL1Id ||
    businessIndustry?.industryL2Id !== previousBusinessIndustry?.industryL2Id ||
    businessIndustry?.industryL3Id !== previousBusinessIndustry?.industryL3Id
}

const hasSelectionChanged = (selectedOptions?: { [key: string]: boolean }, previousSelectedOptions?: { [key: string]: boolean }): boolean => {
  if (!selectedOptions && !previousSelectedOptions) {
    return false
  }
  if (!selectedOptions || !previousSelectedOptions) {
    return true
  }
  const selectedOptionsKeys = Object.keys(selectedOptions)
  const previousSelectedOptionsKeys = Object.keys(previousSelectedOptions)
  if (selectedOptionsKeys.length !== previousSelectedOptionsKeys.length) {
    return true
  }
  for (const key of selectedOptionsKeys) {
    if (selectedOptions[key] !== previousSelectedOptions[key]) {
      return true
    }
  }
  return false
}

const buildEventDetail = (questionsWithAnswers: { [key:string]: boolean }): string => {
  return Object.keys(questionsWithAnswers).map((questionId) => {
    const response = questionsWithAnswers[questionId] ? YES : NO
    return `${questionId}${QUESTION_SEPARATOR}${response}`
  }).join(ANSWERS_SEPARATOR)
}

import { IndustryData } from '../contexts/industryContext'
import { AccountProfile } from '../models/AccountProfile'
import { AccountProfileStoredData } from '../models/AccountProfileStoredData'
import { BusinessHowYouDesign } from '../models/BusinessHowYouDesign'
import { BusinessYouOwn } from '../models/BusinessYouOwn'
import { BusinessYouUseFor } from '../models/BusinessYouUseFor'
import { PersonalReason } from '../models/PersonalReason'
import { PersonalWho } from '../models/PersonalWho'
import { DROPDOWN_NOT_SELECTED_VALUE, NO_BUSINESS_OPTION_VALUE } from '../utils/constants'

export const buildEmptyAccountProfileStoredData = (): AccountProfileStoredData => {
  return {
    isNewAccountProfile: true,
    businessYouOwn: [],
    businessYouUseFor: [],
    businessHowYouDesign: [],
    personalReason: [],
    personalWho: [],
  }
}

export const buildAccountProfileToBeStored = (accountProfile: AccountProfile): AccountProfileStoredData => {
  const {
    businessYouOwnOptionsSelected,
    businessYouUseForOptionsSelected,
    businessHowYouDesignOptionsSelected,
    personalReasonForShoppingOptionsSelected,
    personalWhoYouShopForOptionsSelected
  } = accountProfile
  const accountProfileToBeStored: AccountProfileStoredData = {}
  if (hasBusiness(accountProfile)) {
    if (hasSelectedValue(accountProfile.businessUse)) {
      accountProfileToBeStored.businessUse = accountProfile.businessUse
    }
    if (hasContent(accountProfile.businessName)) {
      accountProfileToBeStored.businessName = accountProfile.businessName
    }

    accountProfileToBeStored.businessIndustry = { ...accountProfile.businessIndustry } as IndustryData

    if (hasSelectedValue(accountProfile.businessSize)) {
      accountProfileToBeStored.businessSize = accountProfile.businessSize
    }
    if (hasSelectedValue(accountProfile.businessLaunchedYear) && accountProfile.businessNotLaunchedYet !== true) {
      accountProfileToBeStored.businessLaunchedYear = parseInt(accountProfile.businessLaunchedYear ?? '')
    }

    accountProfileToBeStored.businessNotLaunchedYet = accountProfile.businessNotLaunchedYet
    if (hasSelectedValue(accountProfile.businessStage)) {
      accountProfileToBeStored.businessStage = accountProfile.businessStage
    }
    accountProfileToBeStored.businessYouOwn = setSelectedOptions(businessYouOwnOptionsSelected) as BusinessYouOwn[]
    accountProfileToBeStored.businessYouUseFor = setSelectedOptions(businessYouUseForOptionsSelected) as BusinessYouUseFor[]
    accountProfileToBeStored.businessHowYouDesign = setSelectedOptions(businessHowYouDesignOptionsSelected) as BusinessHowYouDesign[]
  }
  if (hasSelectedValue(accountProfile.personalUse)) {
    accountProfileToBeStored.personalUse = accountProfile.personalUse
  }
  return {
    ...accountProfileToBeStored,
    personalReason: setSelectedOptions(personalReasonForShoppingOptionsSelected) as PersonalReason[],
    personalWho: setSelectedOptions(personalWhoYouShopForOptionsSelected) as PersonalWho[]
  }
}

const hasBusiness = (accountProfile: AccountProfile): boolean => {
  return accountProfile.businessUse !== NO_BUSINESS_OPTION_VALUE
}

const setSelectedOptions = (selectedOptions?: { [key: string]: boolean }): string[] => {
  if (!selectedOptions) {
    return []
  }
  return Object.keys(selectedOptions).filter(option => selectedOptions[option])
}

const hasSelectedValue = (dropdownField?: string) => {
  return dropdownField && dropdownField !== DROPDOWN_NOT_SELECTED_VALUE
}

const hasContent = (field?: string) => {
  return field && field.length > 0
}
